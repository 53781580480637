<template>
  <v-container class="Product_bar">
    <v-row>
      <v-col cols="4">
        <h3 class="System_admin_text_Product ml-2">DEALS ARCHIVE</h3>
      </v-col>

      <v-col cols="12" sm="3"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="text-center">
          <b-button-group>
            <b-button class="tasks-button" @click="openUploads"
              >Uploads</b-button
            >
            <b-button @click="getUserDealsArchived()" class="purple-button"
              >Show Archived</b-button
            >
            <b-button @click="getUserDealsNotArchived()" class="blue-button"
              >Not Archived</b-button
            >
            <!-- <b-button @click="dialogOpen" class="add-button">Add</b-button> -->
          </b-button-group>
        </div>
      </v-col>
    </v-row>
    <!-- <v-row>
            <v-col cols="12" md="4" class="pt-0 pb-0">
                <v-text-field
                        label="Search"
                        v-model="searchText"
                        clearable
                        @click:clear="clearSearch"
                        append-icon="mdi-magnify"
                        @keyup.enter="getData"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
                <v-btn outlined rounded @click="getData">Search</v-btn>
            </v-col>
        </v-row> -->

    <v-col cols="12" class="customer-search-table">
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1 cursor-pointer"
      >
        <template v-slot:item.archived="{ item }">
          <v-simple-checkbox
            v-model="item.archived"
            :true-value="true"
            :false-value="false"
            :ripple="false"
            @input="updateActiveArchive(item.id, 'archive', $event)"
          ></v-simple-checkbox>
        </template>

        <template v-slot:item.active="{ item }">
          <v-simple-checkbox
            v-model="item.active"
            :true-value="true"
            :false-value="false"
            :ripple="false"
            @input="updateActiveArchive(item.id, 'activate', $event)"
          ></v-simple-checkbox>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="canput === 'true'"
            color="warning"
            small
            class="mr-5"
            @click="editedItems(item)"
            >fas fa-edit</v-icon
          >
          <!-- <v-icon
            v-if="candelete === 'true'"
            color="error"
            small
            @click="deleteItem(item.id)"
            >fas fa-trash-alt</v-icon
          > -->
        </template>
      </v-data-table>
    </v-col>

    <v-row>
      <v-col cols="12">
        <div class="header-add-systemAdmin" style="float: right">
          <b-button class="back-button" @click="goBack">Back </b-button>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogEdit" max-width="1200" persistent>
      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click="close">
              <v-img src="../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>
                  {{ editedItem.id ? "Update Deal Record" : "Add Deal" }}
                </h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="px-5">
                      <v-col cols="12" sm="12">
                        <div id="bsText">
                          <b-input-group size="sm">
                            <template #prepend size="sm">
                              <b-input-group-text size="sm"
                                >Deal Name</b-input-group-text
                              >
                            </template>
                            <b-form-input
                              size="sm"
                              v-model="editedItem.deal_name"
                              @change="getDup"
                              persistent-hint
                              :rules="[(v) => !!v || 'This field is required']"
                            ></b-form-input>
                          </b-input-group>
                          <p></p>
                          <p v-if="!dealnameIsValid" class="err-message">
                            Please enter the Deal Name
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <template id="AdminUser">
                          <div>
                            <label><b>Admin User</b></label>
                            <b-form-select
                              v-model="editedItem.admin_user_id"
                              :options="list_admin_user"
                              class="mb-3"
                              value-field="id"
                              text-field="text"
                              disabled-field="notEnabled"
                              :rules="[(v) => !!v || 'This field is required']"
                            ></b-form-select>
                          </div>
                        </template>
                      </v-col>

                      <v-col cols="12" sm="3">
                        <template id="City">
                          <div>
                            <label><b>City</b></label>
                            <b-form-input
                              v-model="editedItem.city"
                              :options="list_city"
                              class="mb-3"
                              value-field="id"
                              text-field="text"
                              disabled-field="notEnabled"
                            ></b-form-input>
                          </div>
                        </template>
                      </v-col>
                      <v-col cols="12" sm="3" class="State">
                        <template id="State">
                          <div>
                            <label><b>State</b></label>
                            <b-form-select
                              v-model="editedItem.state_id"
                              :options="list_states"
                              class="mb-3"
                              value-field="id"
                              text-field="text"
                              disabled-field="notEnabled"
                            ></b-form-select>
                          </div>
                        </template>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <template id="Zip">
                          <div>
                            <label><b>Zip</b></label>
                            <b-form-input
                              v-model="editedItem.zip"
                              v-mask="mask.zipCode"
                              class="mb-3"
                            ></b-form-input>
                          </div>
                        </template>
                      </v-col>
                      <label class="div-header down-15"
                        ><b>Select Brokers</b></label
                      >
                      <v-col cols="10"></v-col>
                      <b-button class="datatable-button" @click="addBroker"
                        >Add Broker</b-button
                      >
                      <v-row>
                        <v-col cols="12" md="4" class="pt-0 pb-0">
                          <v-text-field
                            label="Search"
                            v-model="searchText"
                            clearable
                            @click:clear="clearSearch"
                            append-icon="mdi-magnify"
                            @keyup.enter="getBrokerData"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="">
                          <v-btn outlined rounded @click="getBrokerData"
                            >Search</v-btn
                          >
                        </v-col>
                        <v-col cols="12" md="4" class="">
                          <label style="top-margin: 15px"
                            ><b>Selected Brokers</b>
                          </label>
                        </v-col>
                      </v-row>
                      <v-col cols="6">
                        <div class="down-5">
                          <v-data-table
                            dense
                            class="customer-search-table cursor-pointer"
                            :headers="brokerHeaders"
                            :items="brokerItems"
                            :loading="loading"
                            sort-by="id"
                            sort-desc
                            @click:row="selectBroker"
                            :footer-props="{
                              'items-per-page-options': [3, 5, 7, 10, -1],
                            }"
                            :items-per-page="5"
                          >
                          </v-data-table>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="down-5">
                          <v-data-table
                            dense
                            class="customer-search-table cursor-pointer"
                            :headers="selectedBrokerHeaders"
                            :items="selectedBrokerItems"
                            :loading="loading"
                            sort-by="id"
                            sort-desc
                          >
                            <template v-slot:item.actions="{ item }">
                              <v-icon
                                class="action-icon"
                                v-if="canput === 'true'"
                                color="warning"
                                small
                                @click="editedBrokerItems(item)"
                                >fas fa-edit</v-icon
                              >
                              <v-icon
                                class="action-icon"
                                v-if="candelete === 'true'"
                                color="error"
                                small
                                @click="deleteBrokerItem(item.id)"
                                >fas fa-trash-alt</v-icon
                              >
                            </template>
                          </v-data-table>
                        </div>
                      </v-col>

                      <v-col cols="10"></v-col>
                      <v-col cols="2">
                        <b-button class="save-button" @click="saveFormData"
                          >Save</b-button
                        >
                        <b-button class="uploads-button" @click="showDialog2"
                          >Next</b-button
                        >                        
                      </v-col>

                      <v-col cols="12">
                        <div class="text-right"></div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="1200" persistent>
      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click="close">
              <v-img src="../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>
                  {{ editedItem.id ? "Update Deal Record" : "Add Deal" }}
                </h6>
                <!-- <b-button @click="sendAdminEmail">Test Admin Email</b-button> -->
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="px-5">
                      <v-col cols="12" sm="12">
                        <div id="bsText">
                          <b-input-group size="sm">
                            <template #prepend size="sm">
                              <b-input-group-text size="sm"
                                >Deal Name</b-input-group-text
                              >
                            </template>
                            <b-form-input
                              size="sm"
                              v-model="editedItem.deal_name"
                              @change="getDup"
                              persistent-hint
                              :rules="[(v) => !!v || 'This field is required']"
                            ></b-form-input>
                          </b-input-group>
                          <p></p>
                          <p v-if="!dealnameIsValid" class="err-message">
                            Please enter the Deal Name
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <template id="AdminUser">
                          <div>
                            <label><b>Admin User</b></label>
                            <b-form-select
                              v-model="editedItem.admin_user_id"
                              :options="list_admin_user"
                              class="mb-3"
                              value-field="id"
                              text-field="text"
                              disabled-field="notEnabled"
                              :rules="[(v) => !!v || 'This field is required']"
                            ></b-form-select>
                          </div>
                        </template>
                      </v-col>

                      <v-col cols="12" sm="3">
                        <template id="City">
                          <div>
                            <label><b>City</b></label>
                            <b-form-input
                              v-model="editedItem.city"
                              :options="list_city"
                              class="mb-3"
                              value-field="id"
                              text-field="text"
                              disabled-field="notEnabled"
                            ></b-form-input>
                          </div>
                        </template>
                      </v-col>
                      <v-col cols="12" sm="3" class="State">
                        <template id="State">
                          <div>
                            <label><b>State</b></label>
                            <b-form-select
                              v-model="editedItem.state_id"
                              :options="list_states"
                              class="mb-3"
                              value-field="id"
                              text-field="text"
                              disabled-field="notEnabled"
                            ></b-form-select>
                          </div>
                        </template>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <template id="Zip">
                          <div>
                            <label><b>Zip</b></label>
                            <b-form-input
                              v-model="editedItem.zip"
                              v-mask="mask.zipCode"
                              class="mb-3"
                            ></b-form-input>
                          </div>
                        </template>
                      </v-col>
                      <label class="div-header down-15"
                        ><b>Select Brokers</b></label
                      >
                      <v-col cols="10"></v-col>
                      <b-button class="datatable-button" @click="addBroker"
                        >Add Broker</b-button
                      >
                      <v-row>
                        <v-col cols="12" md="4" class="pt-0 pb-0">
                          <v-text-field
                            label="Search"
                            v-model="searchText"
                            clearable
                            @click:clear="clearSearch"
                            append-icon="mdi-magnify"
                            @keyup.enter="getBrokerData"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="">
                          <v-btn outlined rounded @click="getBrokerData"
                            >Search</v-btn
                          >
                        </v-col>
                        <v-col cols="12" md="4" class="">
                          <label style="top-margin: 15px"
                            ><b>Selected Brokers</b>
                          </label>
                        </v-col>
                      </v-row>
                      <v-col cols="6">
                        <div class="down-5">
                          <v-data-table
                            dense
                            class="customer-search-table cursor-pointer"
                            :headers="brokerHeaders"
                            :items="brokerItems"
                            :loading="loading"
                            sort-by="id"
                            sort-desc
                            @click:row="selectBroker"
                            :footer-props="{
                              'items-per-page-options': [3, 5, 7, 10, -1],
                            }"
                            :items-per-page="5"
                          >
                          </v-data-table>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="down-5">
                          <v-data-table
                            dense
                            class="customer-search-table cursor-pointer"
                            :headers="selectedBrokerHeaders"
                            :items="selectedBrokerItems"
                            :loading="loading"
                            sort-by="id"
                            sort-desc
                          >
                            <template v-slot:item.actions="{ item }">
                              <v-icon
                                class="action-icon"
                                v-if="canput === 'true'"
                                color="warning"
                                small
                                @click="editedBrokerItems(item)"
                                >fas fa-edit</v-icon
                              >
                              <v-icon
                                class="action-icon"
                                v-if="candelete === 'true'"
                                color="error"
                                small
                                @click="deleteBrokerItem(item.id)"
                                >fas fa-trash-alt</v-icon
                              >
                            </template>
                          </v-data-table>
                        </div>
                      </v-col>

                      <v-col cols="11"></v-col>
                      <v-col cols="1">
                        <b-button class="uploads-button" @click="showDialog2"
                          >Next</b-button
                        >
                      </v-col>

                      <v-col cols="12">
                        <div class="text-right"></div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-if="dialog2" v-model="dialog2" max-width="1200" persistent>
      <v-card style="background-color: aquamarine">
        <div>
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click="close">
              <v-img src="../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>
                  {{
                    editedItem.id ? "Update Seller Record" : "Add Deal Seller"
                  }}
                </h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="px-5">
                      <v-col cols="12" sm="12">
                        <div id="bsText">
                          <b-input-group size="sm">
                            <template #prepend size="sm">
                              <b-input-group-text size="sm"
                                >Deal Name</b-input-group-text
                              >
                            </template>
                            <b-form-input
                              size="sm"
                              v-model="editedItem.deal_name"
                              @change="getDup"
                              persistent-hint
                              :rules="[(v) => !!v || 'This field is required']"
                            ></b-form-input>
                          </b-input-group>
                          <p></p>
                          <p v-if="!dealnameIsValid" class="err-message">
                            Please enter the Deal Name
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <template id="AdminUser">
                          <div>
                            <label><b>Admin User</b></label>
                            <b-form-select
                              v-model="editedItem.admin_user_id"
                              :options="list_admin_user"
                              class="mb-3"
                              value-field="id"
                              text-field="text"
                              disabled-field="notEnabled"
                              :rules="[(v) => !!v || 'This field is required']"
                            ></b-form-select>
                          </div>
                        </template>
                      </v-col>

                      <v-col cols="12" sm="3">
                        <template id="City">
                          <div>
                            <label><b>City</b></label>
                            <b-form-input
                              v-model="editedItem.city"
                              :options="list_city"
                              class="mb-3"
                              value-field="id"
                              text-field="text"
                              disabled-field="notEnabled"
                            ></b-form-input>
                          </div>
                        </template>
                      </v-col>

                      <v-col cols="12" sm="3" class="State">
                        <template id="State">
                          <div>
                            <label><b>State</b></label>
                            <b-form-select
                              v-model="editedItem.state_id"
                              :options="list_states"
                              class="mb-3"
                              value-field="id"
                              text-field="text"
                              disabled-field="notEnabled"
                            ></b-form-select>
                          </div>
                        </template>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <template id="Zip">
                          <div>
                            <label><b>Zip</b></label>
                            <b-form-input
                              v-model="editedItem.zip"
                              v-mask="mask.zipCode"
                              class="mb-3"
                            ></b-form-input>
                          </div>
                        </template>
                      </v-col>
                      <label class="div-header down-15"
                        ><b>Select Sellers</b></label
                      >
                      <v-col cols="8"></v-col>
                      <b-button
                            style="text-align: left"
                            class="datatable-button"
                            @click="ghlSeller"
                            >Engage GHL Seller</b-button
                          >                     
                      <b-button
                        style="text-align: right"
                        class="datatable-button"
                        @click="addSeller"
                        >Add Seller</b-button
                      >
                      <v-row>
                        <v-col cols="12" md="4" class="pt-0 pb-0">
                          <v-text-field
                            label="Search"
                            v-model="searchText"
                            clearable
                            @click:clear="clearSearch"
                            append-icon="mdi-magnify"
                            @keyup.enter="getSellerData"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" class="down-15">
                          <v-btn outlined rounded @click="getSellerData"
                            >Search</v-btn
                          >
                        </v-col>
                        <v-col cols="12" md="4" class="">
                          <label style="top-margin: 25px"
                            ><b>Selected Sellers</b>
                          </label>
                        </v-col>
                      </v-row>
                      <v-col cols="6">
                        <div class="down-5">
                          <v-data-table
                            dense
                            class="customer-search-table cursor-pointer"
                            :headers="sellerHeaders"
                            :items="sellerItems"
                            :loading="loading"
                            sort-by="id"
                            sort-desc
                            @click:row="selectSeller"
                            :footer-props="{
                              'items-per-page-options': [3, 5, 7, 10, -1],
                            }"
                            :items-per-page="5"
                          >
                          </v-data-table>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="down-5">
                          <v-data-table
                            dense
                            class="customer-search-table cursor-pointer"
                            :headers="selectedSellerHeaders"
                            :items="selectedSellerItems"
                            :loading="loading"
                            sort-by="id"
                            sort-desc
                          >
                            <template v-slot:item.actions="{ item }">
                              <v-icon
                                class="action-icon"
                                v-if="canput === 'true'"
                                color="warning"
                                small
                                @click="editedSellerItems(item)"
                                >fas fa-edit</v-icon
                              >
                              <v-icon
                                class="action-icon"
                                v-if="candelete === 'true'"
                                color="error"
                                small
                                @click="deleteSellerItem(item.id)"
                                >fas fa-trash-alt</v-icon
                              >
                            </template>
                          </v-data-table>
                        </div>
                      </v-col>
                      <v-col cols="9"></v-col>
                      <v-col cols="2">
                        <b-button-group class="mt-5 ml-2">
                          <b-button @click="showDialog1" class="worksheets-button"
                            >Previous</b-button
                          >
                          <b-button @click="saveFormData" class="save-button"
                            >Save</b-button
                          >                         
                          <b-button @click="close" class="next-button"
                            >Close</b-button
                          >
                        </b-button-group>
                      </v-col>

                      <v-col cols="12">
                        <div class="text-right">
                          <!-- <v-btn color="primary" dark @click="saveFormData">
                                Save
                              </v-btn> -->
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <span>Confirm Delete</span>
        </v-card-title>
        <v-card-text class="subtitle-1 pa-5">
          <span>Are you sure you want to delete this item?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Cancel</v-btn>
          <v-btn text @click="deleteItemConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogBroker" max-width="600" persistent>
          <v-card>
            <div class="">
              <div class="popup-wrap">
                <v-btn class="close-popup" text @click="closeBroker">
                  <v-img
                    src="../assets/close-icon.png"
                    alt="close-icon"
                  ></v-img>
                </v-btn>

                <div class="popup-wrap-inner step-one">
                  <div class="popup-title">
                    <h6>
                      {{
                        brokerItem.id
                          ? "Update Broker Record"
                          : "Add Broker Record"
                      }}
                    </h6>
                  </div>
                  <div class="customer-form">
                    <v-form v-model="valid" ref="form" lazy-validation>
                      <v-container>
                        <v-row class="px-5">
                          <v-row class="pr-10 pl-10">
                            <v-col cols="12" sm="12">
                              <v-row>
                                <v-col cols="12" md="3" class="pt-0 pb-0">
                                  <v-text-field
                                    v-model="brokerItem.uid"
                                    @change="getDupUID(brokerItem .uid)"
                                    label="UID"
                                    :rules="[
                                    (v) => v.trim() !== '' || 'This field is required',
                                    ]"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 pb-0">
                                  <v-text-field
                                    v-model="brokerItem.first_name"
                                    label="First Name"
                                    :rules="[
                                      (v) => v.trim() !== '' || 'This field is required',
                                    ]"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="5" class="pt-0 pb-0">
                                  <v-text-field
                                    v-model="brokerItem.last_name"
                                    label="Last Name"
                                    :rules="[
                                      (v) => v.trim() !== '' || 'This field is required',
                                    ]"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col id="zip" cols="12" md="3" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Zip"
                                    v-model="brokerItem.zip"
                                    v-mask="mask.zipCode"
                                    @change="onZipChange('broker')"
                                  ></v-text-field>
                                </v-col>                                
                                <v-col cols="12" md="5" class="pt-0 pb-0">
                                  <v-autocomplete
                                    label="City"
                                    v-model="brokerItem.city_id"
                                    :items="list_city"
                                    item-value="id"
                                    item-text="text"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 pb-0">
                                  <v-autocomplete
                                    label="State"
                                    v-model="brokerItem.state_id"
                                    :items="list_states"
                                    item-value="id"
                                    item-text="text"
                                    @change="(event) => getZipCode(brokerItem.city_id, brokerItem.state_id, this.brokerItem, 'zip')"
                                  ></v-autocomplete>
                                </v-col>

                              </v-row>

                              <v-row>
                                <v-col cols="12" md="12" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Company"
                                    v-model="brokerItem.company"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Phone"
                                    v-model="brokerItem.phone"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Text"
                                    v-model="brokerItem.text_no"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="12" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Password"
                                    v-model="brokerItem.django_pwd"
                                    :rules="[
                                      (v) => v.trim() !== '' || 'This field is required',
                                    ]"

                                  ></v-text-field>
                                </v-col>
                                <!-- <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-checkbox
                                    label="Active"
                                    v-model="brokerItem.active"
                                    :true-value="true"
                                    :false-value="false"
                                  ></v-checkbox>
                                </v-col> -->
                              </v-row>
                              <v-row> </v-row>

                              <v-row> </v-row>
                            </v-col>
                          </v-row>

                          <v-col cols="12">
                            <div class="text-right">
                              <v-btn
                                color="primary"
                                dark
                                @click="saveBrokerFormData"
                              >
                                Save
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogSeller" max-width="600" persistent>
          <v-card>
            <div class="">
              <div class="popup-wrap">
                <v-btn class="close-popup" text @click="closeSeller">
                  <v-img
                    src="../assets/close-icon.png"
                    alt="close-icon"
                  ></v-img>
                </v-btn>

                <div class="popup-wrap-inner step-one">
                  <div class="popup-title">
                    <h6>
                      {{
                        editedSellerItem.id
                          ? "Update Seller Record"
                          : "Add Seller Record"
                      }}
                    </h6>
                  </div>
                  <div class="customer-form">
                    <v-form v-model="valid" ref="form" lazy-validation>
                      <v-container>
                        <v-row class="px-5">
                          <v-row class="pr-10 pl-10">
                            <v-col cols="12" sm="12">
                              <v-row>
                                <v-col cols="12" md="3" class="pt-0 pb-0">
                                  <v-text-field
                                    v-model="editedSellerItem.uid"
                                    @change="getDupUID(editedSellerItem.uid)"
                                    label="UID"
                                    :rules="[
                                      (v) => !!v || 'This field is required',
                                    ]"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 pb-0">
                                  <v-text-field
                                    v-model="editedSellerItem.first_name"
                                    label="First Name"
                                    :rules="[
                                      (v) => !!v || 'This field is required',
                                    ]"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="5" class="pt-0 pb-0">
                                  <v-text-field
                                    v-model="editedSellerItem.last_name"
                                    label="Last Name"
                                    :rules="[
                                      (v) => !!v || 'This field is required',
                                    ]"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Password"
                                    v-model="editedSellerItem.django_pwd"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Email"
                                    v-model="editedSellerItem.email"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col id="zipSeller" cols="12" md="3" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Zip"
                                    v-model="editedSellerItem.zip"
                                    v-mask="mask.zipCode"
                                    @change="onZipChange()"
                                  ></v-text-field>
                                </v-col>                                
                                <v-col cols="12" md="5" class="pt-0 pb-0">
                                  <v-autocomplete
                                    label="City"
                                    v-model="editedSellerItem.city_id"
                                    :items="list_city"
                                    item-value="id"
                                    item-text="text"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 pb-0">
                                  <v-autocomplete
                                    label="State"
                                    v-model="editedSellerItem.state_id"
                                    :items="list_states"
                                    item-value="id"
                                    item-text="text"
                                    @change="(event) => getZipCode(editedSellerItem.city_id, editedSellerItem.state_id, this.editedSellerItem, 'zip')"
                                  ></v-autocomplete>
                                </v-col>

                              </v-row>
                              <v-row>
                                <v-col cols="12" md="12" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Company"
                                    v-model="editedSellerItem.company"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Phone"
                                    v-model="editedSellerItem.phone"
                                    v-mask="mask.phone"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Text"
                                    v-model="editedSellerItem.text_no"
                                    v-mask="mask.phone"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row> </v-row>

                              <v-row> </v-row>
                            </v-col>
                          </v-row>

                          <v-col cols="12">
                            <div class="text-right">
                              <v-btn
                                color="primary"
                                dark
                                @click="saveSellerFormData"
                              >
                                Save
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>

    <v-dialog v-model="dialogDeleteBroker" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <span>Confirm Delete</span>
        </v-card-title>
        <v-card-text class="subtitle-1 pa-5">
          <span>Are you sure you want to delete this item?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Cancel</v-btn>
          <v-btn text @click="deleteBrokerItemConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteSeller" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <span>Confirm Delete</span>
        </v-card-title>
        <v-card-text class="subtitle-1 pa-5">
          <span>Are you sure you want to delete this item?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Cancel</v-btn>
          <v-btn text @click="deleteSellerItemConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteBuyer" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <span>Confirm Delete</span>
        </v-card-title>
        <v-card-text class="subtitle-1 pa-5">
          <span>Are you sure you want to delete this Buyer?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Cancel</v-btn>
          <v-btn text @click="deleteBuyerItemConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div id="modal-yes-no">
      <b-modal
        id="modal-center"
        v-model="dlgModal"
        centered
        :title="errorTitle"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
      >
        <b-row class="mb-1 text-center">
          <b-col cols="12"></b-col>
          <b-col>{{ errorText }}</b-col>
        </b-row>

        <template #modal-footer>
          <div class="w-100">
            <p class="float-right"></p>
            <b-button-group class="float-right">
              <b-button @click="sendBrokerEmail()" class="offers-button"
                >Yes</b-button
              >
              <b-button @click="dlgModal = false" class="back-button"
                >No</b-button
              >
            </b-button-group>
          </div>
        </template>
      </b-modal>
    </div>

    <div id="AdminModal">
      <b-modal
        id="admin-modal"
        v-model="dlgModalAdmin"
        centered
        :title="errorTitle"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
      >
        <b-row class="mb-1 text-center">
          <b-col cols="12"></b-col>
          <b-col>{{ errorText }}</b-col>
        </b-row>

        <template #modal-footer>
          <div class="w-100">
            <p class="float-right"></p>
            <b-button-group class="float-right">
              <b-button @click="sendAdminEmail()" class="offers-button"
                >Yes</b-button
              >
              <b-button @click="dlgModalAdmin = false" class="back-button"
                >No</b-button
              >
            </b-button-group>
          </div>
        </template>
      </b-modal>
    </div>

    <div>
        <b-modal
          id="modal-center"
          v-model="dlgError"
          centered
          :title="errorTitle"
          :header-bg-variant="headerBgVariant"
          :header-text-variant="headerTextVariant"
          :body-bg-variant="bodyBgVariant"
          :body-text-variant="bodyTextVariant"
          :footer-bg-variant="footerBgVariant"
          :footer-text-variant="footerTextVariant"
        >
          <b-row class="mb-1 text-center">
            <b-col cols="12"></b-col>
            <b-col>{{ errorText }}</b-col>
          </b-row>

          <template #modal-footer>
            <div class="w-100">
              <p class="float-left"></p>
              <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="dlgError = false"
              >
                Close
              </b-button>
            </div>
          </template>
        </b-modal>
      </div>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CommonServices from "../service/CommonServices";
import XLSX from "xlsx";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  created() {
    this.checkPermissions();
    this.logActivity("Opened", this.editedItem.deal_id);
    this.getUserDeals();
    CommonServices.getList("Users/?user_type_id=1")
      .then((res) => {
        console.log("AdminUsers", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_admin_user.push({
            id: code.id,
            text: code.first_name + " " + code.last_name,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList("Users/?user_type_id=2")
      .then((res) => {
        console.log("BrokerUsers: ", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_broker.push({
            id: code.user_id,
            text: code.first_name + " " + code.last_name,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList("Users/?user_type_id=3")
      .then((res) => {
        console.log("SellerUsers", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_seller.push({
            id: code.id,
            text: code.first_name + " " + code.last_name,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList("Cities")
      .then((res) => {
        console.log("Cities", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_city.push({ id: code.id, text: code.city })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList("States")
      .then((res) => {
        console.log("States", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_states.push({ id: code.id, text: code.state })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  },

  data() {
    return {
      componentURL: "Deals",
      componentURL_dealbuyer: "DealBuyers",
      componentURL_archived: "Deals/?archived=True",
      componentURL_notarchived: "Deals/?archived=False",

      componentURL_broker: `DealBrokers/?broker_id=${localStorage.getItem(
        "userid"
      )}`,
      componentURL_broker_archived: `DealBrokers/?archived=True&broker_id=${localStorage.getItem(
        "userid"
      )}`,
      componentURL_broker_notarchived: `DealBrokers/?archived=False&broker_id=${localStorage.getItem(
        "userid"
      )}`,

      componentURL_seller: `DealSellers/?seller_id=${localStorage.getItem(
        "userid"
      )}`,
      componentURL_seller_archived: `DealSellers/?archived=True&broker_id=${localStorage.getItem(
        "userid"
      )}`,
      componentURL_seller_notarchived: `DealSellers/?archived=False&broker_id=${localStorage.getItem(
        "userid"
      )}`,

      componentURL_buyer: `Users/?user_type_id=4`,

      headerBgVariant: "success",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "success",
      footerTextVariant: "dark",
      errorTitle: "",
      errorText: "",

      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      valid: false,
      dialog: false,
      dialogDelete: false,
      dialogDeleteBroker: false,
      dialogDeleteSeller: false,
      dialogDeleteBuyer: false,
      dialogEdit: false,
      dialogBroker: false,
      dialogSeller: false,
      dialog2: false,
      dialog3: false,
      dlgError: false,
      dlgModal: false,
      dlgModalAdmin: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",
      currentDeal: localStorage.getItem("Deal"),
      currentUserType: localStorage.getItem("role"),
      currentUser: localStorage.getItem("userid"),
      currentUserID: localStorage.getItem("userid"),
      currentEmail: localStorage.getItem("email"),
      brokerFname: "",
      brokerLname: "",
      brokerEmail: "",
      adminFname: "",
      adminLname: "",
      adminEmail: "",
      curDealID: localStorage.getItem("DealID"),

      headers: [
        { text: "ACTIONS", value: "actions", sortable: false },
        { text: "DEAL ID", value: "id", sortable: true },
        { text: "DEAL NAME", value: "deal_name", width: "50%", sortable: true },
        { text: "ARCHIVED", value: "archived", sortable: true },
        { text: "ACTIVE", value: "active", sortable: true },
      ],

      brokerHeaders: [
        {
          text: "First Name",
          class: "small-tableheader",
          value: "first_name",
          sortable: true,
        },
        {
          text: "Last Name",
          class: "small-tableheader",
          value: "last_name",
          sortable: true,
        },
        {
          text: "City",
          class: "small-tableheader",
          value: "city",
          sortable: true,
        },
        {
          text: "State",
          class: "small-tableheader",
          value: "state",
          sortable: true,
        },
        {
          text: "Zip",
          class: "small-tableheader",
          value: "zip",
          sortable: false,
        },
      ],

      selectedBrokerHeaders: [
        {
          text: "First Name",
          class: "small-tableheader",
          value: "first_name",
          sortable: true,
        },
        {
          text: "Last Name",
          class: "small-tableheader",
          value: "last_name",
          sortable: true,
        },
        {
          text: "City",
          class: "small-tableheader",
          value: "city",
          sortable: true,
        },
        {
          text: "State",
          class: "small-tableheader",
          value: "state",
          sortable: true,
        },
        {
          text: "Zip",
          class: "small-tableheader",
          value: "zip",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false },
      ],

      sellerHeaders: [
        {
          text: "First Name",
          class: "small-tableheader",
          value: "first_name",
          sortable: true,
        },
        {
          text: "Last Name",
          class: "small-tableheader",
          value: "last_name",
          sortable: true,
        },
        {
          text: "City",
          class: "small-tableheader",
          value: "city",
          sortable: true,
        },
        {
          text: "State",
          class: "small-tableheader",
          value: "state",
          sortable: true,
        },
        {
          text: "Zip",
          class: "small-tableheader",
          value: "zip",
          sortable: false,
        },
      ],

      selectedSellerHeaders: [
        {
          text: "First Name",
          class: "small-tableheader",
          value: "first_name",
          sortable: true,
        },
        {
          text: "Last Name",
          class: "small-tableheader",
          value: "last_name",
          sortable: true,
        },
        {
          text: "City",
          class: "small-tableheader",
          value: "city",
          sortable: true,
        },
        {
          text: "State",
          class: "small-tableheader",
          value: "state",
          sortable: true,
        },
        {
          text: "Zip",
          class: "small-tableheader",
          value: "zip",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false },
      ],

      buyerHeaders: [
        {
          text: "First Name",
          class: "small-tableheader",
          value: "first_name",
          sortable: true,
        },
        {
          text: "Last Name",
          class: "small-tableheader",
          value: "last_name",
          sortable: true,
        },
        {
          text: "City",
          class: "small-tableheader",
          value: "city",
          sortable: true,
        },
        {
          text: "State",
          class: "small-tableheader",
          value: "state",
          sortable: true,
        },
        {
          text: "Zip",
          class: "small-tableheader",
          value: "zip",
          sortable: false,
        },
      ],

      selectedBuyerHeaders: [
        {
          text: "First Name",
          class: "small-tableheader",
          value: "first_name",
          sortable: true,
        },
        {
          text: "Last Name",
          class: "small-tableheader",
          value: "last_name",
          sortable: true,
        },
        {
          text: "City",
          class: "small-tableheader",
          value: "city",
          sortable: true,
        },
        {
          text: "State",
          class: "small-tableheader",
          value: "state",
          sortable: true,
        },
        {
          text: "Zip",
          class: "small-tableheader",
          value: "zip",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false },
      ],

      sellerItems: [],
      brokerItems: [],
      selectedBrokerItems: [],
      selectedSellerItems: [],
      selectedBuyerItems: [],

      items: [],
      buyerItems: [],

      list_admin_user: [],
      list_broker: [],
      list_seller: [],
      list_states: [],
      list_city: [],
      list_buyer: [],

      userActivityItem: {
        user_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DDThh:mm"),
        page: "Tasks",
        action: "",
        deal_id: "",
        json: "",
      },

      Buyer: {
        deal_id: "",
        buyer_id: "",
      },

      editedItem: {
        id: "",
        deal_id: "",
        deal_name: "",
        admin_user_id: "",
        city: "",
        state_id: "",
        zip: "",
      },

      editedSellerItem: {},

      brokerItem: {
        id: "",
        user_type_id: 2,
        first_name: "",
        last_name: "",
        city: "",
        state_id: "",
        zip: "",
      },

      dupItem: {
        id: "",
        deal_name: "",
        admin_user_id: "",
        city: "",
        state_id: "",
        zip: "",
      },

      blindAdItem: {
        id: "",
        deal_id: this.$route.params.id,
      },

      mask: {
        maxDollar: "############",
        zipCode: "#####",
        ssn: "###-##-####",
        value: "######",
        phone: "(###) ###-####",
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  computed: {
    dealnameIsValid() {
      return !!this.editedItem.deal_name;
    },

    formIsValid() {
      return this.dealnameIsValid;
    },

    brokerZip: {
      get() {
        return this.brokerItem.zip;
      },
      set(value) {
        this.brokerItem.zip = value;
      },
    },
    sellerZip: {
      get() {
        return this.sellerItem.zip;
      },
      set(value) {
        this.sellerItem.zip = value;
      },
    },

  },

  methods: {
    async onStateChange(type) {
    if (type=='broker'){
      const { brokerItem } = this;
      await CommonServices.getZipCode(brokerItem.city_id, brokerItem.state_id, brokerItem, 'zip');      
    }else{
      const { editedSellerItem } = this;
      await CommonServices.getZipCode(editedSellerItem.city_id, editedSellerItem.state_id, editedSellerItem, 'zip');      
     
    }
    this.$forceUpdate();
     },

     async onZipChange(type) {
    if (type=='broker'){
      const { brokerItem } = this;
      await CommonServices.getCityState(brokerItem.zip,brokerItem,'city_id','state_id');      
    }else{
      const { editedSellerItem } = this;
      await CommonServices.getCityState(editedSellerItem.zip,editedSellerItem, 'city_id','state_id');      
     
    }
    this.$forceUpdate();
     },

    clearBrokerSearch() {
      this.searchText = "";
      this.getBrokerData();
    },

    async  getZipCode(cityid, stateid, targetObj, targetField) {
      try {
        const endpoint = '/ZipCodes';
        //const payload = { city_id: cityid, state_id: stateid };
        const url = `${endpoint}?city_id=${cityid}&state_id=${stateid}`.trim('/');
        console.log('URL:', url);
        const zipCodes = await CommonServices.getItem(url);
        console.log('Found ZipCodes:', zipCodes);
        if (zipCodes.length === 0) {
          throw new Error('No zip code found for the given city and state.');
        }
        console.log('First Zip', zipCodes.results[0].zipCode);

        const zipCode = zipCodes.results[0].zipCode;
        if (targetObj && targetField) {
          targetObj[targetField] = zipCode;
        }
        this.$forceUpdate();
        console.log('Broker Item Zip:',zipCode)
        return zipCodes.results[0].zipCode;
      } catch (error) {
        console.error(error);
      }
    },




    getDupUID(uid) {
      CommonServices.getList(`Users/?uid=${uid}`)
        .then((res) => {
          console.log("Get Dup UID Response: ", res);
          this.dupItem = res;
          console.log("Dup Item: ", this.dupItem);
          console.log("dupCount: ", this.dupItem.count);
          localStorage.setItem("dupItemID", this.dupItem.count);
          if (this.dupItem.count > 0) {
            console.log("UID already exists!", this.dupItem);
            this.dlgError = true;
            this.errorTitle = "Duplicate UID Name!";
            this.errorText = "This UID already exists...";
            this.headerBgVariant = "danger";
            this.footerBgVariant = "danger";
            this.brokerItem.uid = "";
          }else{
            if(this.dialogBroker==true){
              this.brokerItem.django_pwd=this.generatePassword(10);
            }else{
              this.editedSellerItem.django_pwd=this.generatePassword(10);
            }
            
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },


    generatePassword(length) {
      var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*",
          retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },

    addBroker() {
      this.brokerItem = {};
      this.brokerItem.user_type_id = 2;
      this.brokerItem.active = true;
      this.dialogBroker = true;
    },

    addSeller() {
      this.editedSellerItem = {};
      this.editedSellerItem.user_type_id = 3;
      this.editedSellerItem.active = true;
      this.dialogSeller = true;
    },

    addBuyer() {
      this.editedItem = {};
      this.editedItem.user_type_id = 4;
      this.editedItem.active = true;
      this.dialogBuyer = true;
    },

    getBrokerData() {
      //first refresh the vw_cur_deal_brokers so that previously selected brokers will not be in the available listing
      CommonServices.putData("Functions", {
        id: 7,
        param_one_number: this.editedItems.id,
      });
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      CommonServices.listWithParams(`vwAvailableBrokers`, { params: params })
        .then((response) => {
          this.brokerItems = response.results;
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getSellerData() {
      CommonServices.putData("Functions", {
        id: 8,
        param_one_number: this.editedItems.id,
      });
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      CommonServices.listWithParams(`vwAvailableSellers`, { params: params })
        .then((response) => {
          this.sellerItems = response.results;
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getBuyerData() {
      CommonServices.putData("Functions", {
        id: 9,
        param_one_number: this.editedItems.id,
      });

      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      CommonServices.listWithParams(`vwAvailableBuyers`, { params: params })
        .then((response) => {
          this.buyerItems = response.results;
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getSelectedBrokerData(dealID) {
      console.log("Current Deal ID:", dealID);
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      console.log("Getting Deal Brokers for Deal #: ", dealID);
      CommonServices.listWithParams(`DealBrokers/?deal_id=${dealID}`, {
        params: params,
      })
        .then((response) => {
          this.selectedBrokerItems = response.results;
          console.log("Selected Brokers: ", this.selectedBrokerItems);
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getSelectedSellerData(dealID) {
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      console.log("Getting Deal Sellers for Deal #: ", dealID);
      CommonServices.listWithParams(`DealSellers/?deal_id=${dealID}`, {
        params: params,
      })
        .then((response) => {
          this.selectedSellerItems = response.results;
          console.log("Selected Sellers: ", this.selectedSellerItems);
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getSelectedBuyerData(dealID) {
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      console.log("Getting Deal Buyers for Deal #: ", dealID);
      CommonServices.listWithParams(`DealBuyers/?deal_id=${dealID}`, {
        params: params,
      })
        .then((response) => {
          this.selectedBuyerItems = response.results;
          console.log("Selected Buyers: ", this.selectedBuyerItems);
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getBrokers() {
      //first refresh the vw_cur_deal_brokers so that previously selected brokers will not be in the available listing
      CommonServices.putData("Functions", {
        id: 7,
        param_one_number: this.editedItems.id,
      });
      CommonServices.listWithParams("vwAvailableBrokers", this.items)
        .then((response) => {
          this.brokerItems = response.results;
          console.log("Broker Items: ", this.brokerItems);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSellers() {
      CommonServices.putData("Functions", {
        id: 8,
        param_one_number: this.editedItems.id,
      });

      CommonServices.listWithParams("vwAvailableSellers", this.items)
        .then((response) => {
          this.sellerItems = response.results;
          console.log("Seller Items: ", this.sellerItems);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getBuyers() {
      CommonServices.putData("Functions", {
        id: 9,
        param_one_number: this.editedItems.id,
      });

      CommonServices.listWithParams("vwAvailableBuyers", this.items)
        .then((response) => {
          this.buyerItems = response.results;
          console.log("Buyer Items: ", this.buyerItems);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openSendAdminEmail() {
      this.dlgModalAdmin = true;
      this.errorTitle = "Send Admin Email?";
      this.errorText =
        "Would you like to send an email to the Admin of this account alerting them that they have been assigned to this deal?";
      this.headerBgVariant = "success";
      this.footerBgVariant = "success";
    },

    async sendAdminEmail() {
      await CommonServices.postData("/Twillio/send-email", {
        user_name: `${this.adminFname || "New"} ${this.adminLname || "User"}`,
        to_email: this.adminEmail,
        subject: "NEW DEAL ASSIGNMENT",
        text_content:
          "Thanks for your recent signup on http://petersonclient.com/",
        html_content:
          "<p>Dear " +
          this.adminFname +
          " " +
          this.adminLname +
          ",</p>\
            <p>The following Deal Assignment has just been initiated:</p>\
            <p> &nbsp; &nbsp; Deal Name: " +
          this.editedItem.deal_name +
          "</p>\
            <p> &nbsp; &nbsp; User Name: " +
          this.brokerFname +
          " " +
          this.brokerLname +
          "</p>\
            <p> &nbsp; &nbsp; User Email: " +
          this.brokerEmail +
          "</p>\
            <p><strong>Please login to your PetersonClient account to to review.</strong> </p>",
      });
      this.dlgModalAdmin = false;
    },

    async sendSellerEmail() {
      console.log(
        "Sending seller email...",
        this.adminFname,
        this.adminLname,
        this.adminEmail
      );
      await CommonServices.postData("/Twillio/send-email", {
        user_name: `${this.adminFname || "New"} ${this.adminLname || "User"}`,
        to_email: this.adminEmail,
        subject: "PETERSON CLIENT PENDING UPLOADS",
        text_content:
          "Thanks for your recent signup on http://petersonclient.com/",
        html_content:
          "<p>Dear " +
          this.adminFname +
          " " +
          this.adminLname +
          ",</p>\
          <p>Please find attached the listing of uploads pending on your account.</p>\
          <p><strong>Please login to https://petersonclient.com/ to address these uploads.</strong> </p>",
      });
    },

    showDialog1() {
      this.dialog = true;
      this.dialog2 = false;
    },

    showDialog2() {
      if (!this.editedItem.deal_name) {
        this.dlgError = true;
        this.errorTitle = "You must first specify a Deal Name!";
        this.errorText =
          "Please specify a name for this Deal prior to selecting brokers...";
        this.headerBgVariant = "danger";
        this.footerBgVariant = "danger";
        return;
      }
      console.log(
        "Selected Broker Items/Length",
        this.selectedBrokerItems,
        this.selectedBrokerItems.length
      );
      if (this.selectedBrokerItems.length == 0) {
        this.dlgError = true;
        this.errorTitle = "You must first select a Broker!";
        this.errorText = "Please select a Broker for this Deal";
        this.headerBgVariant = "danger";
        this.footerBgVariant = "danger";
        return;
      }
      this.getSellerData();
      this.dialog = false;
      this.dialog2 = true;
    },

    showDialog3() {
      this.getBuyerData();
      this.dialog2 = false;
      this.dialog3 = true;
    },

    selectBroker(item) {
      //if the user has not yet saved the record then save the record and use the response id
      //to add the selected broker to the DealBrokers table
      if (!this.editedItem.deal_name) {
        this.dlgError = true;
        this.errorTitle = "You must first specify a Deal Name!";
        this.errorText =
          "Please specify a name for this Deal prior to selecting brokers...";
        this.headerBgVariant = "danger";
        this.footerBgVariant = "danger";
        return;
      }
      //this.getDup();
      if (this.currentDealID == 0) {
        CommonServices.addRecord(this.componentURL, this.editedItem)
          .then((response) => {
            console.log("Saved Deal response: ", response);
            localStorage.setItem("ResponseID", response.id);
            this.getAdminData(response.admin_user_id); //get admin data for sending admin email
            this.adminUserID = response.admin_user_id;
            this.currentDealID = response.id;
            this.editedItem.id = response.id;
            this.getDealBrokers(response.id, item.id, item.email);
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackbar = true;
              this.snackColor = "error";
              this.snackText = "Something went wrong";
            }
          });
      } else {
        this.getAdminData(this.editedItem.admin_user_id); //get admin data for sending admin email
        this.adminUserID = this.editedItem.admin_user_id;
        this.getDealBrokers(this.editedItem.id, item.id, item.email);
      }
    },

    selectSeller(item) {
      //if the user has not yet saved the record then save the record and use the response id
      //to add the selected seller to the Dealsellers table
      if (!this.editedItem.deal_name) {
        this.dlgError = true;
        this.errorTitle = "You must first specify a Deal Name!";
        this.errorText =
          "Please specify a name for this Deal prior to selecting sellers...";
        this.headerBgVariant = "danger";
        this.footerBgVariant = "danger";
        return;
      }
      if (this.editedItem.id == 0) {
        CommonServices.addRecord(this.componentURL, this.editedItem)
          .then((response) => {
            console.log("Saved Deal response: ", response);
            localStorage.setItem("ResponseID", response.id);
            this.getAdminData(response.admin_user_id); //get admin data for sending admin email
            this.adminUserID = response.admin_user_id;
            this.currentDealID = response.id;
            this.editedItem.id = response.id;
            this.getDealSellers(response.id, item.id, item.email);
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackbar = true;
              this.snackColor = "error";
              this.snackText = "Something went wrong";
            }
          });
      } else {
        this.getAdminData(this.editedItem.admin_user_id); //get admin data for sending admin email
        this.adminUserID = this.editedItem.admin_user_id;
        this.getDealSellers(this.editedItem.id, item.id, item.email);
      }
    },

    selectBuyer(item) {
      //if the user has not yet saved the record then save the record and use the response id
      //to add the selected Buyer to the DealBuyers table
      if (!this.editedItem.deal_name) {
        this.dlgError = true;
        this.errorTitle = "You must first specify a Deal Name!";
        this.errorText =
          "Please specify a name for this Deal prior to selecting Buyers...";
        this.headerBgVariant = "danger";
        this.footerBgVariant = "danger";
        return;
      }
      if (this.currentDealID == 0) {
        CommonServices.addRecord(this.componentURL, this.editedItem)
          .then((response) => {
            console.log("Saved Deal response: ", response);
            localStorage.setItem("ResponseID", response.id);
            this.getAdminData(response.admin_user_id); //get admin data for sending admin email
            this.adminUserID = response.admin_user_id;
            this.currentDealID = response.id;
            this.editedItem.id = response.id;
            this.getDealBuyers(response.id, item.id, item.email);
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackbar = true;
              this.snackColor = "error";
              this.snackText = "Something went wrong";
            }
          });
      } else {
        this.getAdminData(this.editedItem.admin_user_id); //get admin data for sending admin email
        this.adminUserID = this.editedItem.admin_user_id;
        this.getDealBuyers(this.editedItem.id, item.id, item.email);
      }
    },

    getAdminData(adminID) {
      CommonServices.getList(`Users/${adminID}`)
        .then((res) => {
          this.adminItem = res.results;
          console.log("Admin Res: ", res);
          console.log("Deal Admin: ", res.first_name, res.last_name, res.email);
          this.adminFname = res.first_name;
          this.adminLname = res.last_name;
          this.adminEmail = res.email;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDealBrokers(responseID, brokerID, email) {
      console.log("BrokerID: ", brokerID);
      CommonServices.addRecord("DealBrokers", {
        deal_id: responseID,
        broker_id: brokerID,
      })
        .then((response) => {
          console.log("Selected Broker response: ", response);
          this.getSelectedBrokerData(responseID);
          this.getBrokerData();
          //now send an email to the admin for this deal
          this.brokerFname = response.first_name;
          this.brokerLname = response.last_name;
          this.brokerEmail = email;
          this.openSendAdminEmail("Broker");
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          try {
            Object.keys(error.data).forEach((key) => {
              this.snackText = error.data[key][0];
            });
          } catch (e) {
            this.snackbar = true;
            this.snackColor = "error";
            this.snackText = "Something went wrong";
          }
        });
    },

    openSendBrokerEmail(recipient) {
      this.dlgModal = true;
      this.errorTitle = "Send " + recipient + " Email?";
      this.errorText =
        "Would you like to send an email to this " +
        recipient +
        " alerting them that they have been assigned to this deal?";
      this.headerBgVariant = "success";
      this.footerBgVariant = "success";
    },

    async sendBrokerEmail() {
      console.log("Sending broker email...");
      console.log("broker email", this.brokerEmail);
      await CommonServices.postData("/Twillio/send-email", {
        user_name: `${this.brokerFname || "New"} ${this.brokerLname || "User"}`,
        to_email: this.brokerEmail,
        subject: "NEW DEAL ASSIGNMENT",
        text_content:
          "Thanks for your recent signup on http://petersonclient.com/",
        html_content:
          "<p>Dear " +
          this.brokerFname +
          " " +
          this.brokerLname +
          ",</p>\
          <p>You have just been assigned to the following Deal:</p>\
          <p> &nbsp; &nbsp; User Name: " +
          this.editedItem.deal_name +
          "</p>\
          <p><strong>Please login to your PetersonClient account to ensure all is working properly.</strong> </p>",
      });
      this.dlgModal = false;
    },

    getDealSellers(responseID, sellerID, email) {
      console.log("SellerID: ", sellerID);
      console.log("DealID: ", responseID);
      CommonServices.addRecord("DealSellers", {
        deal_id: responseID,
        seller_id: sellerID,
      })
        .then((response) => {
          console.log("Selected Seller response: ", response);
          this.getSelectedSellerData(this.editedItem.id);
          this.getSellerData();
          //now send an email to the admin for this deal
          this.brokerFname = response.first_name;
          this.brokerLname = response.last_name;
          this.brokerEmail = email;
          this.openSendAdminEmail("Broker");
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          try {
            Object.keys(error.data).forEach((key) => {
              this.snackText = error.data[key][0];
            });
          } catch (e) {
            this.snackbar = true;
            this.snackColor = "error";
            this.snackText = "Something went wrong";
          }
        });
    },

    getDealBuyers(responseID, buyerID, email) {
      console.log("BuyerID: ", buyerID);
      CommonServices.addRecord("DealBuyers", {
        deal_id: responseID,
        buyer_id: buyerID,
      })
        .then((response) => {
          console.log("Selected Buyer response: ", response);
          this.getSelectedBuyerData(this.editedItem.id);
          this.getBuyerData();
          //now send an email to the admin for this deal
          this.brokerFname = response.first_name;
          this.brokerLname = response.last_name;
          this.brokerEmail = email;
          this.openSendAdminEmail("Broker");
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          try {
            Object.keys(error.data).forEach((key) => {
              this.snackText = error.data[key][0];
            });
          } catch (e) {
            this.snackbar = true;
            this.snackColor = "error";
            this.snackText = "Something went wrong";
          }
        });
    },

    checkPermissions() {
      if (this.isAdmin == "false") this.$router.push("/");
    },

    refreshBuyerList(dealid) {
      this.list_buyer = [];
      CommonServices.putData("Functions", { id: 5, param_one_number: dealid })
        .then((response) => {
          console.log("putData Response: ", response.id);
        })
        .catch((error) => {
          console.log("putData Error: ", error);
        });
      CommonServices.getList("vwBuyerList")
        .then((res) => {
          console.log("Buyer List", res);
          let codes = res.results;
          codes.map((code) =>
            this.list_buyer.push({
              id: code.id,
              text: code.buyer,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
      CommonServices.listWithParams(
        `vwDealBuyers/?deal_id=${dealid}`,
        this.buyerItems
      )
        .then((response) => {
          this.buyerItems = response.results;
          console.log("Deal Buyers:", this.buyerItems);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // addBuyer(dealid) {
    //   this.Buyer.deal_id = dealid;
    //   CommonServices.addRecord("DealBuyers", this.Buyer)
    //     .then((response) => {
    //       this.items.push(response);
    //     })
    //     .catch((error) => {
    //       this.snackbar = true;
    //       this.snackColor = "error";
    //       try {
    //         Object.keys(error.data).forEach((key) => {
    //           this.snackText = error.data[key][0];
    //         });
    //       } catch (e) {
    //         this.snackText = "Something went wrong";
    //       }
    //     });
    // },

    logActivity(activity, dealid, json) {
      this.userActivityItem.action = activity;
      this.userActivityItem.logdate = this.$moment(String(Date())).format(
        "YYYY-MM-DDThh:mm"
      );
      this.userActivityItem.deal_id = dealid;
      this.userActivityItem.json = json;
      CommonServices.postData("UserActivityLog", this.userActivityItem)
        .then((response) => {
          console.log("UserActivityLog Response: ", response.id);
        })
        .catch((error) => {
          console.log("Post Activity Error: ", error);
        });
    },

    //---------ALL DEALS-------------------
    getUserDeals() {
      console.log("Current User: ", this.currentUser);
      console.log("Current User Type: ", this.currentUserType);
      if (this.currentUserType == 1) {
        this.getAdminDeals();
      }
      if (this.currentUserType == 2) {
        this.getBrokerDeals();
      }
      if (this.currentUserType == 3) {
        this.getSellerDeals();
      }
    },

    getAdminDeals() {
      CommonServices.listWithParams(this.componentURL, this.items)
        .then((response) => {
          console.log("Admin Items: ", response.results);
          this.items = response.results;
          //localStorage.setItem('Deal', this.items[0].deal)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getBrokerDeals() {
      CommonServices.getList(this.componentURL_broker)
        .then((res) => {
          console.log("Broker Deals: ", res);
          this.items = res.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSellerDeals() {
      CommonServices.getList(this.componentURL_seller)
        .then((res) => {
          console.log("Seller Deals: ", res);
          this.items = res.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //---------------------------------------------

    //---------ARCHIVED DEALS-------------------
    getUserDealsArchived() {
      console.log("Current User: ", this.currentUser);
      console.log("Current User Type: ", this.currentUserType);
      if (this.currentUserType == 1) {
        this.getAdminDealsArchived();
      }
      if (this.currentUserType == 2) {
        this.getBrokerDealsArchived();
      }
      if (this.currentUserType == 3) {
        this.getSellerDealsArchived();
      }
    },

    getAdminDealsArchived() {
      CommonServices.listWithParams(this.componentURL_archived, this.items)
        .then((response) => {
          console.log("Admin Items: ", response.results);
          this.items = response.results;
          //localStorage.setItem('Deal', this.items[0].deal)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getBrokerDealsArchived() {
      CommonServices.getList(this.componentURL_broker_archived)
        .then((res) => {
          console.log("Broker Deals: ", res);
          this.userDeals = res.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSellerDealsArchived() {
      CommonServices.getList(this.componentURL_seller_archived)
        .then((res) => {
          console.log("Seller Deals: ", res);
          this.userDeals = res.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //---------------------------------------------

    //---------NOT ARCHIVED DEALS-------------------

    getUserDealsNotArchived() {
      console.log("Current User: ", this.currentUser);
      console.log("Current User Type: ", this.currentUserType);
      if (this.currentUserType == 1) {
        this.getAdminDealsNotArchived();
      }
      if (this.currentUserType == 2) {
        this.getBrokerDealsNotArchived();
      }
      if (this.currentUserType == 3) {
        this.getSellerDealsNotArchived();
      }
    },

    getAdminDealsNotArchived() {
      CommonServices.listWithParams(this.componentURL_notarchived, this.items)
        .then((response) => {
          console.log("Admin Items: ", response.results);
          this.items = response.results;
          //localStorage.setItem('Deal', this.items[0].deal)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getBrokerDealsNotArchived() {
      CommonServices.getList(this.componentURL_broker_notarchived)
        .then((res) => {
          console.log("Broker Deals: ", res);
          this.userDeals = res.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSellerDealsNotArchived() {
      CommonServices.getList(this.componentURL_seller_notarchived)
        .then((res) => {
          console.log("Seller Deals: ", res);
          this.userDeals = res.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //---------------------------------------------

    openEdit() {
      this.editedItem.length = 0;
      console.log("ID: ", this.editedItem.id);
      this.dialogEdit = true;
    },

    dialogOpen() {
      this.currentDealID = 0;
      this.getBrokers();
      this.getSellers();
      this.getBuyers();
      this.selectedBrokerItems = [];
      this.selectedSellerItems = [];
      this.selectedBuyerItems = [];
      console.log("Admin/Id", this.isAdmin, this.currentUserID);
      if (this.isAdmin === "true") {
        console.log("Setting Admin ID...");
        this.editedItem.admin_user_id = this.currentUserID;
      } else {
        console.log("Setting Default Admin...");
        this.editedItem.admin_user_id = 43;
      }
      this.dialog = true;
      //this.change_step(1)
    },

    openTasks() {
      this.$router.push(`/Tasks/${this.$route.params.id}`);
    },

    openOffers() {
      localStorage.setItem("DealID", this.$route.params.id);
      this.$router.push(`/DealOffers/${this.$route.params.id}`);
    },

    openBlindAd() {
      localStorage.setItem("DealID", this.$route.params.id);
      this.$router.push(`/BlindAdWorksheet/${this.$route.params.id}`);
    },

    openWorksheets() {
      localStorage.setItem("DealID", this.$route.params.id);
      this.$router.push(`/WorkSheets/`);
    },

    openUploads() {
      //First write any missing Deal Folders to the upload table
      //localStorage.setItem("DealID", this.$route.params.id);
      CommonServices.putData("Functions", { id: 3 })
        .then((response) => {
          console.log("Put Function Response: ", response.id);
          setTimeout(() => {
            this.$router.push(`/Uploads/${this.curDealID}`);
          }, 500);
        })
        .catch((error) => {
          console.log("Put Function Error: ", error);
        });
    },

    getCompletedData() {
      console.log("Completed URL: ", this.componentURL_completed);
      CommonServices.listWithParams(this.componentURL_completed, this.items)
        .then((response) => {
          this.items = response.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getIncompleteData() {
      CommonServices.listWithParams(this.componentURL_incomplete, this.items)
        .then((response) => {
          this.items = response.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    validateDeal() {
      this.getDup(this.editedItem.deal_name);
    },

    getDup() {
      CommonServices.getList(`Deals/?deal_name=${this.editedItem.deal_name}`)
        .then((res) => {
          console.log("Get Dup Response: ", res);
          this.dupItem = res;
          console.log("Dup Item: ", this.dupItem);
          console.log("dupCount: ", this.dupItem.count);
          localStorage.setItem("dupItemID", this.dupItem.count);
          if (this.dupItem.count > 0) {
            console.log("Deal name already exists!", this.dupItem);
            this.dlgError = true;
            this.errorTitle = "Duplicate Deal Name!";
            this.errorText = "This Deal Name already exists...";
            this.headerBgVariant = "danger";
            this.footerBgVariant = "danger";
            this.editedItem.deal_name = "";
          } else {
            //If the current user is a Broker; select that broker from the Brokers list
            if (localStorage.getItem("role") === "2") {
              console.log("Selecting current broker...");
              this.selectCurrentBroker();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    selectCurrentBroker() {
      //if the user has not yet saved the record then save the record and use the response id
      //to add the selected broker to the DealBrokers table
      if (this.currentDealID == 0) {
        CommonServices.addRecord(this.componentURL, this.editedItem)
          .then((response) => {
            console.log("Saved Deal response: ", response);
            localStorage.setItem("ResponseID", response.id);
            this.getAdminData(response.admin_user_id); //get admin data for sending admin email
            this.adminUserID = response.admin_user_id;
            this.currentDealID = response.id;
            this.editedItem.id = response.id;
            this.getDealBrokers(
              response.id,
              this.currentUserID,
              this.currentEmail
            );
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackbar = true;
              this.snackColor = "error";
              this.snackText = "Something went wrong";
            }
          });
      } else {
        this.getDealBrokers(
          this.currentDealID,
          this.currentUserID,
          this.currentEmail
        );
      }
    },

    deleteSellerItemConfirm() {
      CommonServices.deleteRecord("DealSellers", this.deletedId)
        .then(() => {
          let index = this.selectedSellerItems.findIndex(
            (item) => item.id === this.deletedId
          );
          this.selectedSellerItems.splice(index, 1);
          this.getSellerData();
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "Record successfully deleted";
          // this.logActivity(
          //   "Deleted Record",
          //   this.editedItem.client_id,
          //   JSON.stringify(this.editedItem)
          // );
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = error;
          console.log(error);
        });
      this.closeDelete();
    },

    deleteSellerItem(id) {
      this.deletedId = id;
      this.dialogDeleteSeller = true;
    },

    exportToExcel() {
      // On Click Excel download button
      // export json to Worksheet of Excel
      var reportWS = XLSX.utils.json_to_sheet(this.items);
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheets to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, reportWS, "Tasks"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "Tasks.xlsx");
    },

    updateActiveArchive(deal_id, action, newValue) {
      CommonServices.postData("Deals/toggle-active-archive", {
        deal_id,
        action,
        new_value: newValue,
      }).catch(() => {
        this.snackColor = "error";
        this.snackText = `Deal could not be ${action}ed`;
        this.snackbar = true;
      });
    },

    saveFormData() {
        if (this.editedItem.id) {
          CommonServices.updateData(this.componentURL, this.editedItem)
            .then((response) => {
              const index = this.items.findIndex(
                (item) => item.id === response.id
              );
              this.$set(this.items, index, response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully updated";
              this.closeEdit();
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.response.data).forEach((key) => {
                  this.snackText = error.response.data[key][0];
                });
              } catch (e) {
                //this.snackText = "Something went wrong";
              }
            });
        } else {
          CommonServices.addRecord(this.componentURL, this.editedItem)
            .then((response) => {
              console.log("Saved item: ", response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully added";
              this.openDeals();
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.data).forEach((key) => {
                  this.snackText = error.data[key][0];
                });
              } catch (e) {
                this.snackbar = true;
                this.snackColor = "error";
                this.snackText = "Something went wrong";
              }
            });
        }
        this.close();
    },


    saveBrokerFormData() {
        if (this.brokerItem.id) {
          this.brokerItem.id=this.brokerItem.broker_id //since we're putting to a User record use broker id
          CommonServices.updateData("Users", this.brokerItem)
            .then((response) => {
              const index = this.items.findIndex(
                (item) => item.id === response.id
              );
              //window.alert('Got here...')
              this.getSelectedBrokerData(this.brokerItem.deal_id);
              this.getBrokerData();              
              this.$set(this.selectedBrokerItems, index, response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully updated";
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.response.data).forEach((key) => {
                  this.snackText = error.response.data[key][0];
                });
              } catch (e) {
                this.snackText = "Error in saveBrokerFormData() ";
              }
            });
        } else {
          CommonServices.addRecord("Users", this.brokerItem)
            .then((response) => {
              console.log("Saved item: ", response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully added";
              this.getBrokerData();
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.data).forEach((key) => {
                  this.snackText = error.data[key][0];
                });
              } catch (e) {
                this.snackbar = true;
                this.snackColor = "error";
                this.snackText = "Error adding record in saveBrokerFormData()";
              }
            });
        }
        this.dialogBroker = false;

    },

    saveSellerFormData() {
      if (this.$refs.form.validate()) {
        if (this.editedSellerItem.id) {
          CommonServices.updateData("Users", this.editedSellerItem)
            .then((response) => {
              const index = this.selectedSellerItems.findIndex(
                (item) => item.id === response.id
              );
              this.getSelectedSellerData(this.editedSellerItem.deal_id);
              this.getSellerData();              
              this.$set(this.editedSellerItem, index, response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully updated";
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.response.data).forEach((key) => {
                  this.snackText = error.response.data[key][0];
                });
              } catch (e) {
                this.snackText = "Error updating record in saveSellerFormData()";
              }
            });
        } else {
          CommonServices.addRecord("Users", this.editedSellerItem)
            .then((response) => {
              console.log("Saved item: ", response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully added";
              this.getSellerData();
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.data).forEach((key) => {
                  this.snackText = error.data[key][0];
                });
              } catch (e) {
                this.snackbar = true;
                this.snackColor = "error";
                this.snackText = "Something went wrong";
              }
            });
        }
        this.dialogSeller = false;
      }
    },

    getData() {
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      CommonServices.listWithParams(this.componentURL, { params: params })
        .then((response) => {
          this.items = response.results;
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteItem(id) {
      this.deletedId = id;
      this.dialogDelete = true;
    },

    deleteBrokerItem(id) {
      this.deletedId = id;
      this.dialogDeleteBroker = true;
    },

    deleteBuyerItem(id) {
      this.deletedId = id;
      this.dialogDeleteBuyer = true;
    },

    deleteItemConfirm() {
      CommonServices.deleteRecord(this.componentURL, this.deletedId)
        .then(() => {
          let index = this.items.findIndex(
            (item) => item.id === this.deletedId
          );
          this.items.splice(index, 1);
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "Record successfully deleted";
          this.logActivity(
            "Deleted Record",
            this.editedItem.deal_id,
            JSON.stringify(this.editedItem)
          );
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = error;
          console.log(error);
        });
      this.closeDelete();
    },

    deleteBrokerItemConfirm() {
      CommonServices.deleteRecord("DealBrokers", this.deletedId)
        .then(() => {
          let index = this.selectedBrokerItems.findIndex(
            (item) => item.id === this.deletedId
          );
          this.selectedBrokerItems.splice(index, 1);
          this.getBrokerData();
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "Record successfully deleted";
          // this.logActivity(
          //   "Deleted Record",
          //   this.editedItem.client_id,
          //   JSON.stringify(this.editedItem)
          // );
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = error;
          console.log(error);
        });
      this.closeDelete();
    },

    deleteBuyerItemConfirm() {
      CommonServices.deleteRecord("DealBuyers", this.deletedId)
        .then(() => {
          let index = this.selectedBuyerItems.findIndex(
            (item) => item.id === this.deletedId
          );
          this.selectedBuyerItems.splice(index, 1);
          this.getBuyerData();
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "Record successfully deleted";
          this.logActivity(
            "Deleted Deal Buyer",
            this.editedItem.deal_id,
            JSON.stringify(this.editedItem)
          );
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = error;
          console.log(error);
        });
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDeleteBroker = false;
      this.dialogDeleteSeller = false;
      this.dialogDeleteBuyer = false;
    },

    closeBroker() {
      this.dialogBroker = false;
    },

    closeSeller() {
      this.dialogSeller = false;
    },

    editedBrokerItems (item) {
      this.brokerItem = Object.assign({}, item);
      this.brokerItem.user_type_id=2;
      this.brokerItem.city_id=parseInt(this.brokerItem.city_id);
      this.brokerItem.state_id=parseInt(this.brokerItem.state_id);
      this.dialogBroker = true;
    },
    
    editedSellerItems (item) {
      console.log('Passed in Item:',item)
      this.editedSellerItem = Object.assign({}, item);
      this.editedSellerItem.id=this.editedSellerItem.seller_id;
      this.editedSellerItem.user_type_id=3;
      this.editedSellerItem.city_id=parseInt(this.editedSellerItem.city_id);
      this.editedSellerItem.state_id=parseInt(this.editedSellerItem.state_id);       
      this.dialogSeller = true;
      console.log('editedSellerItem: ',this.editedSellerItem)
    },

    editedItems(item) {
      this.editedItem = Object.assign({}, item);

      console.log("Current Item:", this.item);
      //first refresh the vw_cur_deal_brokers/sellers/buyers so that previously selected brokers and available brokers lists will match the current deal
      console.log("Updating current deal brokers for DealId: ", item.id);
      CommonServices.putData("Functions", {
        id: 7,
        param_one_number: item.id,
      });
      CommonServices.putData("Functions", {
        id: 8,
        param_one_number: item.id,
      });
      CommonServices.putData("Functions", {
        id: 9,
        param_one_number: item.id,
      });
      this.getBrokers();
      this.getSellers();
      this.getBuyers();
      this.getSelectedBrokerData(this.editedItem.id);
      this.getSelectedSellerData(this.editedItem.id);
      this.getSelectedBuyerData(this.editedItem.id);
      setTimeout(() => {
        this.dialogEdit = true;
      }, 100);
    },

    clearSearch() {
      this.searchText = "";
      this.getBrokerData();
      this.getSellerData();
    },

    close() {
      //this.$refs.form.reset();
      this.resetItems();
      this.dialog = false;
      this.dialogEdit = false;
      this.dialog2 = false;
      this.dialog3 = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
        this.editedIndex = -1;
      });
    },

    resetItems() {
      this.editedItem = {};
    },

    closeEdit() {
      //this.$refs.form.reset();
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
        this.editedIndex = -1;
      });
    },

    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
button {
  color: white;
  border-radius: 2px;
  cursor: pointer;
  font-size: 12px;
}
.close-popup.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 !important;
  height: auto;
  width: auto;
  min-width: auto;
}
.close-popup .v-image__image {
  width: 20px !important;
  object-fit: contain !important;
  background-size: contain !important;
}

.close-popup.v-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  padding: 0 !important;
}
.close-popup .v-image__image {
  width: 20px !important;
  object-fit: contain !important;
  background-size: contain !important;
}

.uploads-button {
  background-color: rgba(81, 109, 131, 255);
  border: none;
  border-radius: 8px;
  border-radius: 18px;
}

.save-button {
  background-color: rgba(124, 96, 121, 255);
  border: none;
  border-radius: 8px;
  border-radius: 18px;
}

.next-button {
  background-color: blue;
  border: none;
  border-radius: 18px;
  border-radius: 8px;
  width: 90px;
}
.datatable-button {
  background-color: rgb(3, 51, 3);
  height: 35px;
  width: auto;
  margin-left: 57px;
  margin-right: 0px;
  font-size: 12px;
  text-align: center;
}

.offers-button {
  background-color: rgba(145, 9, 31, 255);
  margin-left: 0px;
  margin-right: 8px;
  height: 36px;
  width: 125px;
}
.tasks-button {
  background-color: rgba(160, 152, 131, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.blue-button {
  background-color: rgb(13, 13, 68);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.add-button {
  background-color: rgba(194, 88, 48, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.back-button {
  background-color: rgba(0, 0, 0, 255);
  border: none;
  border-radius: 8px;
  border-radius: 1px;
  height: 36px;
  width: 125px;
}

.worksheets-button {
  background-color: rgba(0, 0, 0, 255);
  border: none;
  height: 36px;
  width: 100px;
  margin-left: 8px;
  border-radius: 8px;
}

.showall-button {
  background-color: rgba(227, 223, 212, 255);
  border: none;
  color: black;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.completed-button {
  background-color: rgba(81, 109, 131, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
  height: 36px;
  width: 125px;
}

.incomplete-button {
  background-color: rgba(81, 109, 131, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
  height: 36px;
  width: 125px;
}
.purple-button {
  background-color: rgba(124, 96, 121, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.orange-button {
  background-color: rgba(194, 86, 47, 255);
  border: none;
  margin-left: 8px;
  margin-right: 8px;
  height: 36px;
  width: 125px;
}

.biege-button {
  background-color: rgba(227, 223, 212, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.blah-button {
  background-color: rgba(160, 152, 131, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

input#file-input {
  display: none;
}

input#file-input + label {
  background-color: #0157a0;
  padding: 4px;
  color: #fff;
  border: 2px solid #85bdee;
  border-radius: 9px;
}

input#file-input + label:hover {
  background-color: #20e0a5;
  border-color: #fff;
  cursor: pointer;
}
.err-message {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: red;
  line-height: 0pt;
}

.small-lbl {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 0pt;
}

.flex-end {
  justify-content: flex-end;
}

.headline {
  background-color: red;
  color: white;
}

.header-add-Product {
  text-align: right;
  margin-left: 0%;
}

.header-add-Product button {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #20e0a5;
  box-shadow: none !important;
  color: #fff !important;
  padding: 8px 12px !important;
  border-radius: 60px;
}

.header-add-Product:focus,
.header-add-Product button:focus {
  outline: none !important;
}

.header-add-systemAdmin a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0157a0;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}

@media only screen and (max-width: 768px) {
  .header-add-Product {
    margin-left: 0 !important;
  }
}

.flex-end {
  justify-content: flex-end;
}

.header-add-Product {
  text-align: right;
  margin-left: 0%;
}

.popup-wrap {
  padding: 40px 0;
}

.header-add-Product button {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #20e0a5;
  box-shadow: none !important;
  color: #fff !important;
  padding: 8px 12px !important;
  border-radius: 60px;
}
.popup-title h6 {
  font-size: 22px;
}

.popup-title {
  padding: 0;
}
.popup-title h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  color: #000000;
}

.popup-title {
  padding: 0 50px;
  text-align: center;
}
.popup-wrap-inner.step-three .customer-search-table .v-data-footer {
  width: 100% !important;
  bottom: 0 !important;
  margin-top: 0 !important;
}

.header-add-Product:focus,
.header-add-Product button:focus {
  outline: none !important;
}

.header-add-systemAdmin a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0157a0;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}

@media only screen and (max-width: 768px) {
  .header-add-Product {
    margin-left: 0 !important;
  }
}
</style>
